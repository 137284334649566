import { SiInstagram, SiVk } from 'react-icons/si';

import { Button } from '@/shared/components/ui/button';
import { pagesPath } from '@/shared/config/$path';

import { FooterColumn } from './main-footer-column';

export const MainFooter = () => {
  return (
    <footer className="flex flex-col border-t border-gray-200">
      <div className="container py-5">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
          <FooterColumn
            title="Навигация"
            items={[
              { name: 'Главная', url: pagesPath.$url().pathname },
              { name: 'Косметика', url: pagesPath.products.$url().pathname },
              { name: 'Мастер класс', url: pagesPath.classes.$url().pathname },
              { name: 'Косметология', url: pagesPath.cosmetology.$url().pathname },
              { name: 'Онлайн консультации', url: pagesPath.online_consultation.$url().pathname },
            ]}
          />
          <FooterColumn
            title="Компания"
            items={[
              { name: 'Контакты', url: pagesPath.contacts.$url().pathname },
              { name: 'Доставка', url: pagesPath.delivery.$url().pathname },
            ]}
          />

          <div className="ml-auto flex w-full flex-col items-start text-brand md:items-end">
            <div className="text-sm">Ежедневно с 10:00 до 22:00</div>

            <div className="flex flex-col">
              <a href="tel:+79119628060">8 (911) 962-80-60</a>
            </div>

            <div className="flex space-x-2">
              <Button size="icon" variant="link" asChild>
                <a href="!#">
                  <SiVk fontSize="1.5rem" />
                </a>
              </Button>

              <Button size="icon" variant="link" asChild>
                <a href="!#">
                  <SiInstagram fontSize="1.5rem" />
                </a>
              </Button>
            </div>

            <div className="flex flex-col">
              <a href="mailto:info@marital.pro">info@marital.pro</a>
            </div>
          </div>
        </div>
      </div>

      <div className="border-t border-gray-200">
        <div className="container flex h-16 items-center justify-center">
          <div className="text-sm text-gray-500">© Все права защищены 2023. Marital Cosmetics</div>
        </div>
      </div>
    </footer>
  );
};
