import NextLink from 'next/link';
import { capitalize } from '@effable/misc';
import { SiInstagram, SiWhatsapp } from 'react-icons/si';

import { CartLink } from '@/features/cart-link';

import { pagesPath } from '@/shared/config/$path';
import { APP_TITLE } from '@/shared/lib/seo/meta';

export const MainHeader = () => {
  return (
    <header className="py-4 text-brand md:py-9">
      <div className="container flex flex-col items-center md:flex-row">
        <div className="flex w-full flex-1 items-center">
          <div className="hidden md:flex">
            <NextLink href={pagesPath.$url()} className="text-8xl leading-[0.8]">
              {APP_TITLE.charAt(0).toUpperCase()}
            </NextLink>

            <div className="ml-2 hidden self-end md:block">
              <div className="text-2xl leading-none">{capitalize(APP_TITLE)}</div>
              <div className="text-md leading-none">cosmetics</div>
            </div>
          </div>

          <div className="flex w-full flex-col items-end space-y-2 md:ml-auto md:w-auto">
            <div className="flex w-full justify-between md:justify-end">
              <NextLink href={pagesPath.$url()} className="block text-8xl leading-[0.8] md:hidden">
                {APP_TITLE.charAt(0).toUpperCase()}
              </NextLink>

              <div className="flex flex-col space-y-0.5">
                <a className="flex items-center justify-end" href="tel:+79119628060">
                  <SiWhatsapp className="mr-2" /> 8 (911) 962-80-60
                </a>
                <a className="text-right" href="mailto:info@marital.pro" target="_blank">
                  info@marital.pro
                </a>
                <a
                  className="flex items-center justify-end"
                  href="https://www.instagram.com/marital_cosmetics/"
                  target="_blank"
                >
                  <SiInstagram className="mr-2" /> marital_cosmetics
                </a>
              </div>
            </div>

            <CartLink />
          </div>
        </div>
      </div>
    </header>
  );
};
