import NextLink from 'next/link';
import { useRouter } from 'next/router';

import { cn } from '@/shared/lib/cn';

interface NavigationItemProps {
  title: string;
  href: string;
}

export const NavigationItem = (props: NavigationItemProps) => {
  const { title, href } = props;

  const { asPath } = useRouter();

  const isActive = href === '/' && asPath !== '/' ? false : asPath.includes(href.slice(1));

  return (
    <li className={cn('h-full list-none', isActive && 'border-b-2 border-white')}>
      <NextLink href={href} className="flex h-full w-full items-center px-2">
        {title}
      </NextLink>
    </li>
  );
};
