import NextLink from 'next/link';

export interface FooterColumnItem {
  name: string;
  url: string;
}

export interface FooterColumnProps {
  title: string;
  items: FooterColumnItem[];
}

export const FooterColumn = (props: FooterColumnProps) => {
  const { title, items } = props;

  return (
    <div>
      <div className="mb-2 text-lg">{title}</div>

      <div className="flex flex-col items-start space-y-2">
        {items.map((item) => (
          <NextLink key={item.name} href={item.url}>
            {item.name}
          </NextLink>
        ))}
      </div>
    </div>
  );
};
