import type { OptionalQuery as OptionalQuery_18v8qld } from '../../pages/classes/signup/index.page';

const buildSuffix = (url?: { query?: Record<string, string>; hash?: string }) => {
  const query = url?.query;
  const hash = url?.hash;
  if (!query && !hash) return '';
  const search = query ? `?${new URLSearchParams(query)}` : '';
  return `${search}${hash ? `#${hash}` : ''}`;
};

export const pagesPath = {
  cart: {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/cart' as const, hash: url?.hash }),
  },
  classes: {
    signup: {
      $url: (url?: { query?: OptionalQuery_18v8qld | undefined; hash?: string | undefined } | undefined) => ({
        pathname: '/classes/signup' as const,
        query: url?.query,
        hash: url?.hash,
      }),
    },
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/classes' as const, hash: url?.hash }),
  },
  contacts: {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/contacts' as const, hash: url?.hash }),
  },
  cosmetology: {
    _id: (id: string | number) => ({
      $url: (url?: { hash?: string | undefined } | undefined) => ({
        pathname: '/cosmetology/[id]' as const,
        query: { id },
        hash: url?.hash,
      }),
    }),
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/cosmetology' as const, hash: url?.hash }),
  },
  delivery: {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/delivery' as const, hash: url?.hash }),
  },
  online_consultation: {
    $url: (url?: { hash?: string | undefined } | undefined) => ({
      pathname: '/online-consultation' as const,
      hash: url?.hash,
    }),
  },
  products: {
    _id: (id: string | number) => ({
      $url: (url?: { hash?: string | undefined } | undefined) => ({
        pathname: '/products/[id]' as const,
        query: { id },
        hash: url?.hash,
      }),
    }),
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/products' as const, hash: url?.hash }),
  },
  $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/' as const, hash: url?.hash }),
};

export type PagesPath = typeof pagesPath;

export const staticPath = {
  favicon_ico: '/favicon.ico',
  next_svg: '/next.svg',
  robots_txt: '/robots.txt',
  sitemap_0_xml: '/sitemap-0.xml',
  sitemap_xml: '/sitemap.xml',
  static: {
    banner: {
      banner_1_jpg: '/static/banner/banner-1.jpg',
      banner_2_jpg: '/static/banner/banner-2.jpg',
      banner_3_jpg: '/static/banner/banner-3.jpg',
      banner_4_jpg: '/static/banner/banner-4.jpg',
      banner_5_jpg: '/static/banner/banner-5.jpg',
      banner_6_jpg: '/static/banner/banner-6.jpg',
      banner_7_jpg: '/static/banner/banner-7.jpg',
      banner_8_jpg: '/static/banner/banner-8.jpg',
    },
    products: {
      $1001: {
        $1_jpg: '/static/products/1001/1.jpg',
        $2_jpg: '/static/products/1001/2.jpg',
        $3_jpg: '/static/products/1001/3.jpg',
        $4_jpg: '/static/products/1001/4.jpg',
        $5_jpg: '/static/products/1001/5.jpg',
        $6_jpg: '/static/products/1001/6.jpg',
      },
      $1002: {
        $1_jpg: '/static/products/1002/1.jpg',
        $2_jpg: '/static/products/1002/2.jpg',
        $3_jpg: '/static/products/1002/3.jpg',
        $4_jpg: '/static/products/1002/4.jpg',
      },
      $1003: {
        $1_jpg: '/static/products/1003/1.jpg',
        $2_jpg: '/static/products/1003/2.jpg',
        $3_jpg: '/static/products/1003/3.jpg',
        $4_jpg: '/static/products/1003/4.jpg',
        $5_jpg: '/static/products/1003/5.jpg',
      },
      $1004: {
        $1_jpg: '/static/products/1004/1.jpg',
        $2_jpg: '/static/products/1004/2.jpg',
        $3_jpg: '/static/products/1004/3.jpg',
        $4_jpg: '/static/products/1004/4.jpg',
        $5_jpg: '/static/products/1004/5.jpg',
        $6_jpg: '/static/products/1004/6.jpg',
        $7_jpg: '/static/products/1004/7.jpg',
        $8_jpg: '/static/products/1004/8.jpg',
        $9_jpg: '/static/products/1004/9.jpg',
      },
      $1005: {
        $1_jpg: '/static/products/1005/1.jpg',
        $2_jpg: '/static/products/1005/2.jpg',
        $3_jpg: '/static/products/1005/3.jpg',
        $4_jpg: '/static/products/1005/4.jpg',
        $5_jpg: '/static/products/1005/5.jpg',
        $6_jpg: '/static/products/1005/6.jpg',
      },
      $1006: {
        $1_jpg: '/static/products/1006/1.jpg',
        $2_jpg: '/static/products/1006/2.jpg',
        $3_jpg: '/static/products/1006/3.jpg',
        $4_jpg: '/static/products/1006/4.jpg',
        $5_jpg: '/static/products/1006/5.jpg',
      },
      $1007: {
        $1_jpg: '/static/products/1007/1.jpg',
        $2_jpg: '/static/products/1007/2.jpg',
        $3_jpg: '/static/products/1007/3.jpg',
        $4_jpg: '/static/products/1007/4.jpg',
        $5_jpg: '/static/products/1007/5.jpg',
      },
      $1008: {
        $1_jpg: '/static/products/1008/1.jpg',
        $2_jpg: '/static/products/1008/2.jpg',
        $3_jpg: '/static/products/1008/3.jpg',
        $4_jpg: '/static/products/1008/4.jpg',
        $5_jpg: '/static/products/1008/5.jpg',
        $6_jpg: '/static/products/1008/6.jpg',
        $7_jpg: '/static/products/1008/7.jpg',
        $8_jpg: '/static/products/1008/8.jpg',
        $9_jpg: '/static/products/1008/9.jpg',
      },
      $1009: {
        $1_jpg: '/static/products/1009/1.jpg',
        $2_jpg: '/static/products/1009/2.jpg',
        $3_jpg: '/static/products/1009/3.jpg',
        $4_jpg: '/static/products/1009/4.jpg',
        $5_jpg: '/static/products/1009/5.jpg',
        $6_jpg: '/static/products/1009/6.jpg',
      },
      $1010: {
        $1_jpg: '/static/products/1010/1.jpg',
        $2_jpg: '/static/products/1010/2.jpg',
        $3_jpg: '/static/products/1010/3.jpg',
        $4_jpg: '/static/products/1010/4.jpg',
        $5_jpg: '/static/products/1010/5.jpg',
        $6_jpg: '/static/products/1010/6.jpg',
      },
      $1011: {
        $1_jpg: '/static/products/1011/1.jpg',
        $2_jpg: '/static/products/1011/2.jpg',
        $3_jpg: '/static/products/1011/3.jpg',
        $5_jpg: '/static/products/1011/5.jpg',
      },
      $1012: {
        $1_jpg: '/static/products/1012/1.jpg',
        $2_jpg: '/static/products/1012/2.jpg',
        $3_jpg: '/static/products/1012/3.jpg',
        $4_jpg: '/static/products/1012/4.jpg',
        $5_jpg: '/static/products/1012/5.jpg',
      },
      $1013: {
        $1_jpg: '/static/products/1013/1.jpg',
        $2_jpg: '/static/products/1013/2.jpg',
        $3_jpg: '/static/products/1013/3.jpg',
        $4_jpg: '/static/products/1013/4.jpg',
        $5_jpg: '/static/products/1013/5.jpg',
        $6_jpg: '/static/products/1013/6.jpg',
        $7_jpg: '/static/products/1013/7.jpg',
      },
      $1014: {
        $1_jpg: '/static/products/1014/1.jpg',
        $2_jpg: '/static/products/1014/2.jpg',
        $3_jpg: '/static/products/1014/3.jpg',
        $4_jpg: '/static/products/1014/4.jpg',
        $5_jpg: '/static/products/1014/5.jpg',
      },
      $1015: {
        $1_jpg: '/static/products/1015/1.jpg',
        $2_jpg: '/static/products/1015/2.jpg',
        $3_jpg: '/static/products/1015/3.jpg',
        $4_jpg: '/static/products/1015/4.jpg',
        $5_jpg: '/static/products/1015/5.jpg',
      },
      $1016: {
        $1_jpg: '/static/products/1016/1.jpg',
        $2_jpg: '/static/products/1016/2.jpg',
        $3_jpg: '/static/products/1016/3.jpg',
        $4_jpg: '/static/products/1016/4.jpg',
      },
      $1017: {
        $1_jpg: '/static/products/1017/1.jpg',
        $2_jpg: '/static/products/1017/2.jpg',
        $3_jpg: '/static/products/1017/3.jpg',
        $4_jpg: '/static/products/1017/4.jpg',
        $5_jpg: '/static/products/1017/5.jpg',
        $6_jpg: '/static/products/1017/6.jpg',
        $7_jpg: '/static/products/1017/7.jpg',
      },
      $1018: {
        $1_jpg: '/static/products/1018/1.jpg',
        $2_jpg: '/static/products/1018/2.jpg',
        $3_jpg: '/static/products/1018/3.jpg',
        $4_jpg: '/static/products/1018/4.jpg',
        $5_jpg: '/static/products/1018/5.jpg',
        $6_jpg: '/static/products/1018/6.jpg',
      },
      $1019: {
        $1_jpg: '/static/products/1019/1.jpg',
        $2_jpg: '/static/products/1019/2.jpg',
        $3_jpg: '/static/products/1019/3.jpg',
        $4_jpg: '/static/products/1019/4.jpg',
        $5_jpg: '/static/products/1019/5.jpg',
        $6_jpg: '/static/products/1019/6.jpg',
        $7_jpg: '/static/products/1019/7.jpg',
        $8_jpg: '/static/products/1019/8.jpg',
      },
      $1020: {
        $1_jpg: '/static/products/1020/1.jpg',
        $2_jpg: '/static/products/1020/2.jpg',
        $3_jpg: '/static/products/1020/3.jpg',
        $4_jpg: '/static/products/1020/4.jpg',
      },
      $1021: {
        $1_jpg: '/static/products/1021/1.jpg',
        $2_jpg: '/static/products/1021/2.jpg',
        $3_jpg: '/static/products/1021/3.jpg',
        $4_jpg: '/static/products/1021/4.jpg',
        $5_jpg: '/static/products/1021/5.jpg',
        $6_jpg: '/static/products/1021/6.jpg',
      },
    },
  },
  vercel_svg: '/vercel.svg',
} as const;

export type StaticPath = typeof staticPath;
