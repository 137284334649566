import NextLink from 'next/link';
import { useUnit } from 'effector-react';
import { RiShoppingCartLine } from 'react-icons/ri';

import { $cartItemsAmount } from '@/entities/cart';

import { Button } from '@/shared/components/ui/button';
import { pagesPath } from '@/shared/config/$path';

export const CartLink = () => {
  const amount = useUnit($cartItemsAmount);

  return (
    <Button size="icon-lg" variant="outline" className="relative" asChild>
      <NextLink href={pagesPath.cart.$url()}>
        <span className="sr-only">Корзина</span>

        <RiShoppingCartLine />

        {amount > 0 && <div className="relative -right-1 -top-1">{amount}</div>}
      </NextLink>
    </Button>
  );
};
