import { NavigationItem } from './navigation-item';

export const Navigation = () => {
  return (
    <div className="relative overflow-hidden bg-brand text-white">
      <div className="absolute bottom-0 left-0 top-0 w-5 bg-gradient-to-r from-brand to-transparent" />

      <nav className="flex h-12 items-center overflow-auto px-2 md:justify-center">
        <ul className="flex h-full items-stretch space-x-2 whitespace-nowrap">
          <NavigationItem title="Главная" href="/" />
          <NavigationItem title="Косметика" href="/products" />
          <NavigationItem title="Мастер класс" href="/classes" />
          <NavigationItem title="Косметология" href="/cosmetology" />
          <NavigationItem title="Онлайн консультации" href="/online-consultation" />
          <NavigationItem title="Контакты" href="/contacts" />
        </ul>
      </nav>

      <div className="absolute bottom-0 right-0 top-0 w-5 bg-gradient-to-l from-brand to-transparent" />
    </div>
  );
};
