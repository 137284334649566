import { Navigation } from '@/shared/components/navigation/navigation';

import { MainFooter } from './main-footer';
import { MainHeader } from './main-header';

interface MainLayoutProps {
  children?: React.ReactNode;
}

export const MainLayout = (props: MainLayoutProps) => {
  const { children } = props;

  return (
    <div className="flex min-h-full flex-col">
      <MainHeader />
      <Navigation />

      <main className="flex flex-1 flex-col">{children}</main>

      <MainFooter />
    </div>
  );
};
