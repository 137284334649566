import { combine, createEvent, createStore, sample } from 'effector';
import { create } from 'mutative';

import { Product } from '@/shared/api';
import { createDeclination } from '@/shared/lib/declination';

export interface CartProduct {
  product: Product;
  amount: number;
}

type Cart = Record<string, CartProduct>;

const getProductDeclination = createDeclination({
  one: 'товар',
  few: 'товара',
  many: 'товаров',
});

export const $cart = createStore<Cart>({});

export const $cartItemsAmount = combine($cart, (cart) => Object.keys(cart).length);
export const $cartItemsFullAmount = combine($cart, (cart) =>
  Object.values(cart).reduce((acc, { amount }) => acc + amount, 0),
);
export const $cartItemsAmountString = combine(
  $cartItemsFullAmount,
  (itemsAmount) => `${getProductDeclination(itemsAmount)}`,
);
export const $cartList = combine($cart, (cart) => Object.values(cart));
export const $totalAmount = combine($cart, (cart) =>
  Object.values(cart).reduce((acc, { amount, product }) => acc + amount * product.price, 0),
);

export const cartCleared = createEvent();
export const addedProductToCart = createEvent<Product>();
export const removedProductFromCart = createEvent<Product>();

export const incrementProductAmount = createEvent<Product>();
export const decrementProductAmount = createEvent<Product>();

sample({
  clock: [addedProductToCart, incrementProductAmount],
  source: $cart,
  fn: (cart, addedProduct) => {
    const newCart = create(cart, (draft) => {
      draft[addedProduct.id] = {
        product: addedProduct,
        amount: draft[addedProduct.id] ? draft[addedProduct.id].amount + 1 : 1,
      };
    });

    return newCart;
  },
  target: $cart,
});

sample({
  clock: decrementProductAmount,
  source: $cart,
  fn: (cart, product) => {
    const newCart = create(cart, (draft) => {
      if (draft[product.id].amount === 1) {
        delete draft[product.id];

        return;
      }

      draft[product.id].amount -= 1;
    });

    return newCart;
  },
  target: $cart,
});

sample({
  clock: removedProductFromCart,
  source: $cart,
  fn: (cart, addedProduct) => {
    const newCart = create(cart, (draft) => {
      delete draft[addedProduct.id];
    });

    return newCart;
  },
  target: $cart,
});

sample({
  clock: cartCleared,
  fn: () => ({}),
  target: $cart,
});
